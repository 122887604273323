<template>
  <secondary-page-layout :title="$t('chat')">
    <div class="chat">
      <div class="chat-area">
        <div v-for="message in messages" :key="message.id" class="message" :class="message.isMine ? 'my-message' : 'other-message'">
          <div class="message-content" ref="anchor">
            <div v-html="message.text" class="injected-html"></div>

            <ul v-if="message.original.type === 'actions'" class="pt-3" >
              <li v-for="action in message.original.actions" class="message__inner-btn" :key="action.id" @click="performAction(action)">
                {{ action.text }}
              </li>
            </ul>
          </div>
          <span class="time">{{ message.time }}</span>
        </div>
      </div>

      <div v-if="messages.length === 0" @click="startConversation" class="primary-btn bottom-page-btn">Начать</div>
    </div>
    <transition name="slide-fade">
      <faq-alter-modal :isFAQShown="isFAQShown" @closed="closeAlterModal" v-if="isFAQShown"/>
    </transition>
  </secondary-page-layout>
</template>

<script>
import FaqAlterModal from "../../components/Modals/FaqAlterModal";
import {mapGetters} from "vuex";

export default {
  name: "Chat",
  components: {
    FaqAlterModal
  },
  data() {
    return {
      messages: [],
      newMessage: null,
      isFAQShown: false
    }
  },
  computed: {
    ...mapGetters([
        'authUser'
    ]),
    userId() {
      return this.authUser.id;
    },
    isMessageEmpty() {
      return (!this.newMessage || /^\s*$/.test(this.newMessage));
    }
  },
  async mounted() {
    await this.startConversation()

    if(!this.messages.length) {
      await this.startConversation()
    }
  },
  methods: {
    async startConversation() {
      const loader = this.$loading.show({});

      console.log('start')
      await this.callAPI('/start', false, null, () => {
        loader.hide();
      })
      console.log('ab');
    },
    async callAPI(text, interactive = false, attachment = null, callback) {
          const postData = {
              driver: 'web',
              userId: this.userId,
              message: text,
              attachment,
              interactive,
          };

          await window.axios.post('botman', postData).then(response => {
              const messages = response.data.messages || [];
              messages.forEach(msg => {
                  this._addMessage(msg.text, msg.attachment, false, msg);
              });
              if (callback) {
                  callback(response.data);
              }
          });
      },
      performAction(action) {
        const { value, text, additional } = action;
        const { entity, value: additionalValue } = additional;

        if(entity === 'link' && additionalValue) {
          this.$router.push(additionalValue)
          return;
        }

        this._addMessage(text, null, true);
        this.callAPI(value, true, null, () => {});
      },
      scrollToElement() {
        const el = this.$refs.anchor;
        if (el.length) {
          // Use el.scrollIntoView() to instantly scroll to the element
          el[el.length - 1].scrollIntoView({behavior: "smooth"});
        }
      },
      _addMessage(text, attachment, isMine, original = {}) {
          this.messages.push({
              'isMine': isMine,
              'user': isMine ? '👨' : '🤖',
              'text': text,
              'original': original,
              'attachment': attachment || {},
              'time': new Date().toLocaleTimeString('en-US', {
                hour12: false,
                hour: "numeric",
                minute: "numeric"
              }),
          });
      },
      sendMessage() {
        if (this.isMessageEmpty) {
          return;
        }
        let messageText = this.newMessage;
        this.newMessage = '';
        if (messageText === 'clear') {
            this.messages = [];
            return;
        }
        this._addMessage(messageText, null, true);
        this.callAPI(messageText);
      },
      uploadFile() {
        this.$refs.fileInput.click();
      },
      closeAlterModal() {
        this.isFAQShown = false;
      },

  },
  watch: {
    messages() {
      this.$nextTick(()=>this.scrollToElement())
      }
    }
}
</script>

<style lang="scss" scoped>
.chat {
  padding: 0 16px 0;
  //height: 100%;
  margin-top: auto;

  .chat-area {
    padding-bottom: 60px;
    .message {
      width: 100%;
      display: flex;
      margin-bottom: 16px;
      align-items: flex-end;

      .time {
        font-size: 14px;
        color: #99A3B3;
      }

      .message-content {
        word-wrap: break-word;
        max-width: 75%;
        width: auto;
        padding: 12px 16px;
      }
    }

    .other-message {
      flex-direction: row;

      .time {
        margin-left: 8px;
      }

      .message-content {
        background: #FFFFFF;
        border-radius: 0 16px 16px 16px;
        text-align: left;
      }
    }

    .my-message {
      flex-direction: row-reverse;

      .time {
        margin-right: 8px;
      }

      .message-content {
        background: #2AA65C;
        border-radius: 16px 16px 0 16px;
        //text-align: right;
        max-width: fit-content;
        box-sizing: content-box;
        div {
          color: #fff;
        }
      }
    }
  }

  .chat-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 48px;
    background: rgba(247, 247, 247, 0.8);
    box-shadow: inset 0 0.5px 0 rgba(126, 126, 126, 0.2);
    backdrop-filter: blur(40px);
    padding-bottom: 32px;
    padding-top: 10px;

    .input-file {
      display: none;
    }

    .textarea {
      width: 220px;
      height: 36px;
      padding: 8px 12px;
      overflow-y: hidden;
    }
  }
}

.message__inner-btn {
  background: #F1F2F6;
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.slide-fade-enter-active {
  transition: all 700ms ease;
}

.slide-fade-leave-active {
  transition: all 700ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */
{
  transform: translateY(3000px);
  opacity: 0;
}
</style>
