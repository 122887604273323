<template>
  <div class="alter-modal">
    <div class="alter-modal-header" v-touch:swipe.bottom="closeAlterModal">
      <div class="line"></div>
      <div class="heading">
        <h1 class="title">FAQ</h1>
        <icon icon-name="close-modal-icon" class="icon" @click.native="closeAlterModal"/>
      </div>
    </div>
    <div class="alter-modal-body">
      <div class="accordion" v-for="(item, index) in faqList" :key="index">
        <div class="accordion-head" @click="openAccordion(index)" :class="{'active': activeAccordions.includes(index)}">
          <p class="title">
            {{ item.title }}
          </p>
            <icon icon-name="accordion-arrow-icon" class="accordion-arrow"/>
        </div>
        <div class="accordion-body" :class="{'active': activeAccordions.includes(index)}">
          <div class="accordion-body-content">
            {{ item.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqAlterModal",
  props: {
    isFAQShown: {
      type: Boolean
    }
  },
  data() {
    return {
      activeAccordions: [],
      // faqContent: [
      //   {title: 'Месторасположение и график работы отделений', content: 'С месторасположением и графиком работы отделений можете ознакомиться здесь.'},
      //   {title: 'Какие отделения работают в выходные дни?', content: ''},
      //   {title: 'Как пользоваться чат-ботом?', content: ''},
      //   {title: 'Как оформить полис?', content: ''},
      // ],
      faqList: []
    }
  },
  created() {
    this.getFaqList();
  },
  methods: {
    closeAlterModal() {
      this.$emit('closed')
    },
    async getFaqList() {
      const {data} = await window.axios.get('/faq/list');
      this.faqList = data.data;
    },
    openAccordion(index) {
      let i = this.activeAccordions.indexOf(index);
      if(this.activeAccordions.includes(index)) {
        this.activeAccordions.splice(i, 1);
      } else {
        this.activeAccordions.push(index);
      }
    }
  }
}
</script>

<style lang="scss">
@import "src/sass/variables";
.alter-modal {
  width: 100%;
  z-index: 10;
  background-color: #fff;
  position: fixed;
  top: 70px;
  min-height: 100vh;
  .alter-modal-header {
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 12px;
    border-bottom: 1px solid #C8C7CC;
    .line {
      width: 24px;
      height: 3px;
      background-color: #C8C7CC;
      border-radius: 24px;
      margin-bottom: 5px;
    }
    .heading {
      position: relative;
      width: 100%;
      .icon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        right: 0;
      }
    }
    .title {
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }
  }
  .alter-modal-body {
    padding: 16px;
  }
}
.accordion {
  padding: 16px 0;
  background: #fff;
  margin-bottom: 0;
  border-bottom: 1px solid #C8C7CC;
  .accordion-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: all .3s;
    margin-bottom: 16px;
    &.active {
      .title {
        color: $primary-color;
        transition: all .3s;
      }
      .accordion-arrow {
        transform: rotate(180deg);
        transition: all .3s;
        * {
          fill: $primary-color !important;
        }
      }
    }
    .title {
      transition: all .3s;
      margin-bottom: 0;
    }
    .accordion-arrow {
      transition: all .3s;
    }
  }
  .accordion-body {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: all .3s;
    &.active {
      transition: all .3s;
      max-height: 300px;
    }
    .accordion-body-content {
      background: #F1F2F6;
      border-radius: 12px;
      padding: 16px;
      font-size: 14px;
    }
  }
}
</style>
